import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import visionLogo from '../assets/img/vision-logo1.png';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   setButtonText('Sending...');
  //   let response = await fetch('http://localhost:5000/contact', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json;charset=utf-8',
  //     },
  //     body: JSON.stringify(formDetails),
  //   });
  //   setButtonText('Send');
  //   let result = await response.json();
  //   setFormDetails(formInitialDetails);
  //   if (result.code == 200) {
  //     setStatus({ succes: true, message: 'Message sent successfully' });
  //   } else {
  //     setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
  //   }
  // };

  return (
    <section className='contact' id='connect'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <>
                  <img
                    className={
                      isVisible ? 'animate__animated animate__zoomIn bannerSpryContact' : ''
                    }
                    src={visionLogo}
                    alt='Contact Us'
                  />
                  <p style={{ fontSize: '20px', paddingTop: '20px', width: '80%' }}>
                    We have the most innovative technology and devices to provide the best service.
                    For inquiries or to learn more about our services, please don't hesitate to
                    reach out to us through the contact form below.
                  </p>
                  <p style={{ fontSize: '20px', fontWeight: '500', margin: '10px 0' }}>PHONE & TEXT:</p>
                  <div
                    // href='tel: +1 902 476 7016'
                    style={{ textDecoration: 'none', color: '#568592', fontSize: '20px' }}>
                    +1 (902) 476 7016
                  </div>
                  <br />
                  <p style={{ fontSize: '20px', fontWeight: '500', margin: '10px 0' }}>EMAIL:</p>
                  <a
                    href='mailto:wavehexca@gmail.com'
                    style={{ textDecoration: 'none', color: '#568592', fontSize: '20px' }}>
                    wavehexca@gmail.com
                  </a>
                </>
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  <h2>Get In Touch</h2>
                  <form
                    action='https://getform.io/f/f1bd59ca-92d9-45ce-a796-e465a2620aa7'
                    method='POST'>
                    <Row>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          name='firstname'
                          type='text'
                          value={formDetails.firstName}
                          placeholder='First Name'
                          onChange={e => onFormUpdate('firstName', e.target.value)}
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          name='lastname'
                          type='text'
                          value={formDetails.lasttName}
                          placeholder='Last Name'
                          onChange={e => onFormUpdate('lastName', e.target.value)}
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          name='email'
                          type='email'
                          value={formDetails.email}
                          placeholder='Email Address'
                          onChange={e => onFormUpdate('email', e.target.value)}
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          name='phone'
                          type='tel'
                          value={formDetails.phone}
                          placeholder='Phone No.'
                          onChange={e => onFormUpdate('phone', e.target.value)}
                        />
                      </Col>
                      <Col size={12} className='px-1'>
                        <textarea
                          name='message'
                          rows='6'
                          value={formDetails.message}
                          placeholder='Message'
                          onChange={e => onFormUpdate('message', e.target.value)}></textarea>
                        <button type='submit'>
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                      {status.message && (
                        <Col>
                          <p className={status.success === false ? 'danger' : 'success'}>
                            {status.message}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
