import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { ProjectCard } from './ProjectCard';
import demo1 from '../assets/photo/demo1.jpg';
import demo2 from '../assets/photo/demo2.jpg';
import demo3 from '../assets/photo/demo3.jpg';
import demo4 from '../assets/photo/demo4.jpg';
import demo5 from '../assets/photo/demo5.jpg';
import demo6 from '../assets/photo/demo6.jpg';
import 'animate.css';

export const Projects = () => {
  const projects = [
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: demo1,
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: demo2,
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: demo3,
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: demo4,
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: demo5,
    },
    {
      title: 'Business Startup',
      description: 'Design & Development',
      imgUrl: demo6,
    },
  ];

  return (
    <section className='project' id='project'>
      <Container>
        <Row>
          <Col size={12}>
            <div className={'animate__animated animate__fadeIn'}>
              <h2> Matterport 3D Virtual Tour</h2>
              <p>
                Step into a world of captivating realism with Matterport 3D Virtual Tour, where
                every detail comes to life, offering an immersive and interactive experience like
                never before. Take real estate marketing to the next level or make memories for our
                home.
              </p>
              <Tab.Container id='projects-tabs' defaultActiveKey='first'>
                <Nav
                  variant='pills'
                  className='nav-pills mb-5 justify-content-center align-items-center'
                  id='pills-tab'>
                  <Nav.Item>
                    <Nav.Link eventKey='first'>3D Tour</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='second'>Photography</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey='third'>Pricing</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content id='slideInUp' className={'animate__animated animate__slideInUp'}>
                  <Tab.Pane eventKey='first'>
                    <div className='houseTour'>
                      <iframe
                        title='townhouse'
                        width='853'
                        height='480'
                        src='https://my.matterport.com/show/?m=G5h8hyqcyQB'
                        frameborder='0'
                        allowfullscreen
                        allow='xr-spatial-tracking'></iframe>
                      <iframe
                        title='apartment'
                        width='853'
                        height='480'
                        src='https://my.matterport.com/show/?m=9FinoZpL4WA'
                        frameborder='0'
                        allowfullscreen
                        allow='xr-spatial-tracking'></iframe>
                      <iframe
                        title='RV'
                        width='853'
                        height='480'
                        src='https://my.matterport.com/show/?m=eTgddU5bpJD'
                        frameborder='0'
                        allowfullscreen
                        allow='xr-spatial-tracking'></iframe>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey='second'>
                    <Row>
                      {projects.map((project, index) => {
                        return <ProjectCard key={index} {...project} />;
                      })}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey='third'>
                    <Row>
                      <Col size={12} sm={6} md={4}>
                        <Card style={{ width: '100%', height: '100%' }}>
                          <Table borderless hover>
                            <thead>
                              <tr>
                                <th
                                  colSpan={2}
                                  style={{
                                    color: '#714F15',
                                    fontSize: '20px',
                                    padding: '10px 20px',
                                  }}>
                                  3D SCAN
                                </th>
                              </tr>
                              <tr>
                                <th colSpan={2} style={{ padding: '0px 20px' }}>
                                  <hr />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  SQFT
                                </td>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  PRICE
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  Less Than 2500
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$249.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  2500 - 3500
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$269.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  3500 - 4500
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$289.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>4500 +</td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  <Nav.Link href='#connect'>Call For A Quote</Nav.Link>
                                </td>
                              </tr>
                              <tr>
                                <th colSpan={2} style={{ padding: '0px 20px' }}>
                                  <hr />
                                </th>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  + 2D FLOOR PLAN
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$50</td>
                              </tr>
                              <tr>
                                <th colSpan={2} style={{ padding: '0px 20px' }}>
                                  <hr />
                                </th>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  + TOUR LABELS
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  $4.99/Label
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card>
                      </Col>
                      <Col size={12} sm={6} md={4}>
                        <Card style={{ width: '100%', height: '100%' }}>
                          <Table borderless hover>
                            <thead>
                              <tr>
                                <th
                                  colSpan={2}
                                  style={{
                                    color: '#714F15',
                                    fontSize: '20px',
                                    padding: '10px 20px',
                                  }}>
                                  3D SCAN + PHTOTGRAPHY
                                </th>
                              </tr>
                              <tr>
                                <th colSpan={2} style={{ padding: '0px 20px' }}>
                                  <hr />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  SQFT
                                </td>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  PRICE
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  Less Than 2500
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$349.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  2500 - 3500
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$439.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  3500 - 4500
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$509.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>4500 +</td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  <Nav.Link href='#connect'>Call For A Quote</Nav.Link>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card>
                      </Col>

                      <Col size={12} sm={6} md={4}>
                        <Card style={{ width: '100%', height: '100%' }}>
                          <Table borderless hover>
                            <thead>
                              <tr>
                                <th
                                  colSpan={2}
                                  style={{
                                    color: '#714F15',
                                    fontSize: '20px',
                                    padding: '10px 20px',
                                  }}>
                                  CLOUD ACTIVE LINK
                                </th>
                              </tr>
                              <tr>
                                <th colSpan={2} style={{ padding: '0px 20px' }}>
                                  <hr />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  DURATION
                                </td>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  PRICE
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  Initial 3-Months
                                </td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>Free</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>1 Month</td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$29.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>3 Months</td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$79.99</td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>1 Year</td>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>$239.99</td>
                              </tr>
                              <tr>
                                <th colSpan={2} style={{ padding: '0px 20px' }}>
                                  <hr />
                                </th>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '7px 20px',
                                    color: '#777777',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                  }}>
                                  Cloud Active Again
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: '0px 20px',
                                    color: '#777777',
                                    fontSize: '15px',
                                    fontWeight: '400',
                                  }}>
                                  (Within two years from scan)
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: '7px 20px', fontWeight: '500' }}>
                                  $50 with first month included
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <img className='background-image-right' src={colorSharp2}></img> */}
    </section>
  );
};
