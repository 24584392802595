import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import loopVideo from "../assets/video/loopVideo1.mp4"

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ['WaveHex Vision', 'WaveHex Vision 3D Virtual Tour'];
  const period = 2000;
  const descriptions =
    "WaveHex Vision, the Halifax leading provider of cutting-edge digital production services, offering unrivaled expertise in Matterport 3D virtual tours. With a passion for delivering immersive experiences, WaveHex Vision revolutionizes the way you showcase your space. Combining advanced technology and creative ingenuity, we capture every detail, transforming any environment into a captivating digital journey. Our team of skilled professionals utilizes state-of-the-art equipment and software to produce high-quality, interactive virtual tours that transport your audience into a realm of boundless exploration. Whether it's for real estate, commercial space, or any industry, WaveHex Vision sets the new standard in digital storytelling.";

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  };

  return (
    <section className='banner' id='home'>
      <Container>
        <Row className='aligh-items-center'>
          <Col xs={12} md={6} xl={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  {/* <span className="tagline">Welcome to my Portfolio</span> */}
                  <h1>
                    {`Hi! Now you are in`}{' '}
                    <span
                      className='txt-rotate'
                      dataPeriod='1000'
                      data-rotate='[ "WaveHex Vision", "WaveHex Vision 3D Virtual Tour" ]'>
                      <span className='wrap'>{text}</span>
                    </span>
                  </h1>
                  <p>{descriptions}</p>
                  {/* <button onClick={() => console.log("connect")}>
                    Let’s Connect <ArrowRightCircle size={25} />
                  </button> */}
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={6}>
            {/* <TrackVisibility> */}
              {/* {({ isVisible }) => ( */}
                {/* <div className={isVisible ? 'animate__animated animate__zoomIn' : ''}> */}
                  {/* <img src={headerImg} alt="Header Img" className="bannerWave"/> */}
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className={"loopVideo"}
                    id={'playVideo'}>
                    <source src={loopVideo} type='video/mp4' />
                  </video>
                {/* </div> */}
              {/* )} */}
            {/* </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
