import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

export const ProjectCard = ({ title, description, imgUrl }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Col size={12} sm={6} md={4}>
        <div
          className='proj-imgbx'
          onClick={() => {
            setOpenModal(true);
          }}>
          <img src={imgUrl} alt='Demo Imgage' />
        </div>
      </Col>
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img className='d-block w-100' src={imgUrl} alt='Demo Imgage' />
        </Modal.Body>
      </Modal>
    </>
  );
};
